import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_text_521234";
    item['en'] = "Instructions";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_text2_261660";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_text2_722071";
    item['en'] = "To get started, please take photos of any mole you are concerned with. You can also use photos which you have previously taken before.";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_button_282064";
    item['en'] = "Add";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_button_840357";
    item['en'] = "Take Photo";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_titletext_681876";
    item['en'] = "Instructions";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_titletext_19800";
    item['en'] = "Instructions";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_text_948541";
    item['en'] = "Current Photos";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_button_365297";
    item['en'] = "Add";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_button_615817";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_titletext_857207";
    item['en'] = "Instructions";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_instructionstext_21616";
    item['en'] = "We need some good instructions lol";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_addphoto_638272";
    item['en'] = "Add";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_addphoto_232977";
    item['en'] = "Done";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_instructionstext_89392";
    item['en'] = "We need some good instructions lol";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_titletext_226931";
    item['en'] = "Uploaded";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_titletext_864521";
    item['en'] = "Done";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_instructionstext_29630";
    item['en'] = "Need some info here";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_addphoto_740960";
    item['en'] = "Take More Photos";

    item = {};
    this.items.push(item);
    item['key'] = "mainpage_takephoto_756484";
    item['en'] = "Take More Photos";
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
