import React, { Component } from 'react';
import '../App.css';
import img_elImage from '../images/Component1_elImage_531938.jpg';
import btn_icon_940458 from '../images/greyX.png';


export default class ImageContainer extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  onClick_Close = (ev) => {
    this.props.removeImage(this.props.numKey)

    //ToDo: remove selected image from MainPageScreen this.state.image
  }

  render() {
    const style_elImage = {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elFab = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_940458+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto'
     };

    return (
      <div className="Component1">
        <div className="layoutFlow">
          <div className="flowRow flowRow_Component1_elImage_531938">
          <div className='elImage'>
            <img className="" style={style_elImage} src={this.props.source}/>
          </div>

          </div>
          <div className='elFab'>
            <img src={btn_icon_940458} className="actionFont" style ={{ cursor: 'pointer',
            pointerEvents: 'auto' }} onClick={this.onClick_Close}/>
          </div>
        </div>
      </div>
    )
  }


}
